import React, { useEffect, useRef, forwardRef } from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import PageSizeDropdown from "../PageSizeDropdown";
import Pagination from "react-js-pagination";
import { CircularProgress } from "@mui/material";

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        className="form-checkbox h-4 w-4"
      />
    );
  }
);


const Datatable = ({ setIds = () => { }, selectedRows = [], columns, data = {docs: [], total: 0, pages: 0, loading: false}, setTableRecord, tableSize = {limit: 5, page: 1}, showCheckbox = true }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns,
      data: data.docs ?? [],
      initialState: { pageIndex: 0, pageSize: 5 }
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => showCheckbox ? (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ) : null,
          Cell: ({ row }) => showCheckbox ? (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ) : null,
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    if (data && data.docs.length > 0) {
      let allIds = selectedFlatRows.map((item) => item.original.id);
      setIds(allIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  // Render the UI for your table
  return (
    <>
      {
        data.loading &&
        <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-white bg-opacity-50 w-full">
          <CircularProgress />
        </div>
      }
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  if (!column.isCheck && index !== 0) return null;
                  return (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <div className="flex flex-row items-center justify-end">
                        <span>{column.render("Header")}</span>
                        {/* Add a sort direction indicator */}
                        <span className="ml-auto">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FiChevronDown className="stroke-current text-2xs" />
                            ) : (
                              <FiChevronUp className="stroke-current text-2xs" />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ backgroundColor: selectedRows?.includes(row.original.id) ? '#2196f359' : '' }}>
                  {row.cells.map((cell, index) => {
                    if (
                      cell.column.isCheck === true ||
                      cell.column.isCheck === undefined
                    ) {
                      return (
                        <td {...cell.getCellProps()}>
                          <div className="flex flex-col">
                            {cell.render("Cell", "Aksi")}
                          </div>
                        </td>
                      );
                    }
                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {(data.docs.length !== 0 && data.pages > 0) && (
        <div className="flex flex-row items-center justify-between mt-4 flex-wrap">
          <Pagination
            color="primary"
            activePage={parseInt(pageIndex)}
            itemsCountPerPage={tableSize.limit}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={(e) => setTableRecord({...tableSize, page: e})}
          />

          <select
            className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
            value={tableSize.limit}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setTableRecord({...tableSize, limit: Number(e.target.value)})
            }}
          >
            <PageSizeDropdown />
          </select>
        </div>
      )}
    </>
  );
};

export default Datatable;