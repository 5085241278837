/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SectionTitle from "../components/section-title";
import VendorPageData from "../components/vendor";
import { useOutsideClick } from "../hooks/OutsideClick";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  vendorAction,
  vendorDeactivateAction,
  updateVendorAction,
} from "../actions";
import { checkAccess } from "../utils/common";
import CsvDowloader from "../components/CsvDowloader";

const Item = ({ onClick, title, id, href, goto }) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <p
          onClick={onClick}
          className="cursor-pointer px-5 py-2  hover:bg-grey-300"
        >
          {title}
        </p>
      </a>
    );
  }
  return (
    <Link to={goto}>
      <p
        onClick={onClick}
        className="cursor-pointer px-5 py-2  hover:bg-grey-300"
      >
        {title}
      </p>
    </Link>
  );
};

const CellRender = (props) => {
  const id = props.data[props.row.index].id;
  const [show, setShow] = useState(false);
  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="relative"
    >
      <p className="cursor-pointer hover:text-grey-500">{props.value}</p>
      {show && (
        <div
          className={`flex ${props.flatRows[props.flatRows.length - 1]?.index === props.row.index
            ? ""
            : "absolute"
            } shadow-lg bottom-20 bg-white rounded-md z-10`}
        >
          {checkAccess(5, "edit") && (
            <Item
              title="Edit"
              id={id}
              onClick={() => console.log("Edit")}
              goto={`/vendor/${id}?vendorId=${props.data[props.row.index].vendorId}`}
            />
          )}
          <Item
            title="Product"
            onClick={() => console.log("Product")}
            goto={`/product?vendorId=${id}`}
          />
          <Item
            title="Orders"
            onClick={() => console.log("Orders")}
            goto={`/orders?vendorId=${props.data[props.row.index].vendorId}`}
          />
          <Item
            title="Switch to"
            onClick={() => console.log("Witch to")}
            href={`https://client-weddingserv.netlify.app/vendor-detail/${id}`}
          />
          <Item
            title="Payment Policy"
            onClick={() => console.log("Payment Policy")}
            goto={`/payment-policy?vendorName=${props.data[props.row.index].vendorName}`}
          />
        </div>
      )}
    </div>
  );
};

const Simple = ({
  columns,
  setIds,
  vendors,
  pagination,
  getPageData,
  getVendorList,
  Loading,
  hiddenCol,
}) => {
  // const vendors = useSelector((state) => state.vendorSlice.vendors);
  // const [limit, setTableLimit] = useState(paginationObject.limit)

  return (
    <>
      {vendors.length > 0 && (
        <VendorPageData
          hiddenCol={hiddenCol}
          columns={columns}
          data={vendors}
          loading={Loading}
          onSubmit={getPageData}
          showSearch={true}
          pagination={pagination}
          getAllVendorProduct={getVendorList}
          setIds={setIds}
        />
      )}
    </>
  );
};

const Menu = ({ onClick, title }) => {
  return (
    <p
      onClick={onClick}
      className="cursor-pointer my-1 px-5 py-2  hover:bg-grey-300"
    >
      {title}
    </p>
  );
};

const MenuCheckBox = ({ title, isCheck, setCheck, index }) => {
  return (
    <div className="flex items-center my-1 px-5 py-2  space-x-2">
      <input
        onChange={() => setCheck(index)}
        checked={isCheck}
        type={"checkbox"}
        id={`checkbox${title}`}
      />
      <label htmlFor={`checkbox${title}`} className="cursor-pointer">
        {title}
      </label>
    </div>
  );
};

const SummaryButton = ({
  summary,
  setSummary,
  title,
  getVendorList,
  paginationObject,
}) => (
  <button
    onClick={() => {
      if (summary === title) {
        setSummary("");
        return;
      }
      // const handlefilter = (type) => {
      if (title === "All") getVendorList(paginationObject, "", "");
      else if (title === "Active") getVendorList(paginationObject, 1, "");
      else if (title === "Pending") getVendorList(paginationObject, 0, "");
      else if (title === "Rejected") getVendorList(paginationObject, 2, "");
      else if (title === "Inactive") getVendorList(paginationObject, 4, "");
      else if (title === "Probation") getVendorList(paginationObject, 3, "");
      // }
      setSummary(title);
    }}
    className={` py-2 px-4 mr-2 rounded shadow-sm ${summary === title
      ? "bg-blue-500 text-white hover:bg-blue-300"
      : "bg-white hover:bg-grey-200"
      }`}
  >
    {title}
  </button>
);

const FilterItem = ({
  onRemovePress,
  index,
  setFilter,
  filters,
  setAddFilter,
}) => {
  const [filterOptions, setFilterOptions] = useState([
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Category Name", value: "catname" },
    { label: "Phone", value: "phone" },
    { label: "Status", value: "status" },
  ]);
  useEffect(() => {
    if (filters.length !== 0) {
      const fil = [...filterOptions];

      const myArrayFiltered = fil.filter((filt) => {
        return !filters.some((val) => {
          return Object.keys(val).includes(filt.value);
        });
      });
      setFilterOptions(myArrayFiltered);
    } else {
      setFilterOptions(filterOptions);
      // setAddFilter([])
    }
  }, [filters]);

  return (
    <div className="flex space-x-2 items-center">
      <select
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
        value=""
        onChange={(e) => {
          const fil = [...filterOptions];
          const myArrayFiltered = fil.filter((filt) => {
            return !filters.some((val) => {
              return Object.keys(val).includes(filt.value);
            });
          });
          setFilter([...filters, (filters[index] = { [e.target.value]: "" })]);
          setFilterOptions(myArrayFiltered);
          setAddFilter([]);
        }}
      >
        <option value="">select</option>
        {filterOptions.map((item, i) => (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

      <input
        type="input"
        value={""}
        className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
      />
    </div>
  );
};

const Index = () => {
  const dispatch = useDispatch();
  const [paginationObject, setPaginationObject] = useState({
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    getVendorList(paginationObject, "", "");
  }, [paginationObject.limit]);

  const getVendorList = (
    paginationObject,
    status = "",
    search,
    name = "",
    phone = "",
    email = "",
    categories = ""
  ) => {
    setIsLoading(true);
    dispatch(
      vendorAction({
        params: {
          limit: paginationObject?.limit,
          page: paginationObject?.page,
          status: status,
          search: search,
          name: name,
          phone: phone,
          email: email,
          categories: categories,
        },
        onSuccess: (data) => {
          setPaginationObject({
            limit: data.limit,
            page: data.page,
            pages: data.totalPages,
            total: data.totalDocs,
          });
          const list = data.docs.map((x, i) => {
            x.primaryServiceOffering = x.categories.map((item) => `${item.name}, `);
            x.createdAt = x.createdAt ? moment(x.createdAt).format("DD-MMM-YYYY") : '-';
            x.endDate = x.endDate ? moment(x.endDate).format("DD-MMM-YYYY") : '-';
            x.termsConditions = x.termsConditions === true ? "Agree" : "Disagree";
            x.status = x.status === 0 ? 'Pending' : x.status === 1 ? 'Active' : x.status === 2 ? 'Rejected' : x.status === 3 ? 'Probation' : x.status === 4 ? 'Inactive' : '';
            x.no = data.totalDocs - (paginationObject.page - 1) * paginationObject.limit - i;
            return x;
          })

          setVendorsData(list);
          setIsLoading(false);
        },
        onFailed: () => { },
      })
    );
  };
  const [columns, setColumns] = useState([
    {
      Header: "No.",
      accessor: "no",
      show: true,
    },
    {
      Header: "Vendor ID",
      accessor: "vendorId",
      isCheck: true,
      show: true,
    },
    {
      Header: "Vendor Name",
      accessor: "vendorName",
      Cell: (props) => <CellRender {...props} />,
      isCheck: true,
      show: true,
    },
    {
      Header: "Email",
      accessor: "email",
      isCheck: true,
      show: true,
    },
    {
      Header: "Phone",
      accessor: "phone",
      isCheck: true,
      show: true,
    },
    {
      Header: "Start Date",
      accessor: "createdAt",
      isCheck: true,
      show: true,
    },
    {
      Header: "End Date",
      accessor: "endDate",
      isCheck: true,
      show: true,
    },
    {
      Header: "Primary Service Offering",
      accessor: "primaryServiceOffering",
      isCheck: true,
      show: true,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <select
            onChange={(e) => {
              dispatch(
                updateVendorAction({
                  params: {
                    id: props.row.original.id,
                    status: e.target.value,
                  },
                  onSuccess: () => {
                    toast.success("Vendor Status Updated!");
                    getVendorList(paginationObject, "", "");
                  },
                  onFailed: () => { },
                })
              );
            }}
            className="bg-white border-2 w-32  rounded-md"
          >
            <option value={1} selected={props.value === 'Active'}>
              Active
            </option>
            <option value={4} selected={props.value === 'Inactive'}>
              Inactive
            </option>
            <option value={0} selected={props.value === 'Pending'}>
              Pending
            </option>
            <option value={2} selected={props.value === 'Rejected'}>
              Rejected
            </option>
            <option value={3} selected={props.value === 'Probation'}>
              Probation
            </option>
          </select>
        );
      },
      isCheck: true,
      show: true,
    },
    {
      Header: "Terms & Conditions",
      accessor: "termsConditions",
      isCheck: true,
      show: true,
    },
    {
      Header: "Stripe ID",
      accessor: "stripeCustomerId",
      isCheck: true,
      show: true,
    },
  ]);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [hiddenCol, setHiddenCol] = useState([]);
  const [addFilter, setAddFilter] = useState([0]);
  const [filters, setFilter] = useState([]);
  const [ids, setIds] = useState([]);
  const [summary, setSummary] = useState("");
  const ref = useOutsideClick(() => setShow(false));
  const refColumns = useOutsideClick(() => setShowColumns(false));
  const refFilter = useOutsideClick(() => setShowFilter(false));
  const [vendorsData, setVendorsData] = useState([]);
  const [Loading, setIsLoading] = useState(false);
  const onselect = (limit) => {
    setPaginationObject({
      limit: limit,
    });
  };

  const handleSearch = (type) => {
    if (type.length === 0 || type.length > 3) {
      getVendorList(paginationObject, "", type);
    }
  };

  const getPageData = (data) => {
    setPaginationObject({
      ...paginationObject,
      limit: data,
    });
    // getVendorList(data, "", 1, "");
  };
  return (
    <>
      <SectionTitle title="Manage Vendors" subtitle="Vendors" />
      <div className="p-2 bg-white border rounded-md">
        <div className="flex justify-between div_box_main">
          <div className="flex p-3">
            <Link to="vendor-add">
              {checkAccess(4, "add") && (
                <button
                  style={{ background: "#990000" }}
                  className={` py-2 px-4 mr-2 rounded shadow-sm ${"text-white "} w-40`}
                >
                  Add New
                </button>
              )}
            </Link>
          </div>
        </div>

        <div className="flex">
          <div className="flex p-3 pr-0 res_button w-full">
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="All"
              // onClick={() => handlefilter("all")}
              getVendorList={getVendorList}
              paginationObject={paginationObject}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Active"
              // onClick={() => handlefilter("active")}
              getVendorList={getVendorList}
              paginationObject={paginationObject}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Pending"
              // onClick={() => handlefilter("pending")}
              getVendorList={getVendorList}
              paginationObject={paginationObject}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Rejected"
              // onClick={() => handlefilter("reject")}
              getVendorList={getVendorList}
              paginationObject={paginationObject}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Probation"
              // onClick={() => handlefilter("probation")}
              getVendorList={getVendorList}
              paginationObject={paginationObject}
            />
            <SummaryButton
              summary={summary}
              setSummary={setSummary}
              title="Inactive"
              // onClick={() => handlefilter("inactive")}
              getVendorList={getVendorList}
              paginationObject={paginationObject}
            />
            <div className="flex border-2 rounded-md hover:border-blue-500 w-full">
              <svg
                aria-hidden="true"
                className="flex justify-self-center self-center focus:w-5 h-5 text-gray-500 dark:text-gray-400 ml-2 text-grey-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 35 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                type="Search"
                placeholder="Search"
                className="w-full"
                onChange={(e) => handleSearch(e.target.value)}
              ></input>
            </div>
          </div>

          <div className="flex p-3 w-full">
            <div ref={refFilter} className="ml-auto">
              <CsvDowloader
                columns={columns}
                data={vendorsData}
                filename="vendors"
              />
              <button
                onClick={() => setShowFilter(!showFilter)}
                className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
              >
                Filter
              </button>

              {showFilter && (
                <div className="relative">
                  <div
                    style={{ left: "-80px" }}
                    className="absolute shadow-lg bottom-20 bg-white rounded-md z-10 px-2 py-2"
                  >
                    <div>Filter By</div>
                    {filters.map((item, index) => (
                      <>
                        <div className="flex space-x-2 items-center">
                          <input
                            type="input"
                            value={Object.keys(item)[0]}
                            className="bg-white  w-32 mt-2 py-1 rounded-md"
                          />

                          {Object.keys(filters[index])[0] !== "status" ? (
                            <input
                              type="input"
                              value={Object.values(item)[0]}
                              onChange={(e) => {
                                const updatedfilter = [...filters];
                                updatedfilter[index] = {
                                  [Object.keys(updatedfilter[index])[0]]:
                                    e.target.value,
                                };
                                let object = Object.assign(
                                  {},
                                  ...Object.values(updatedfilter)
                                );
                                if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "name"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    object.status,
                                    "",
                                    object.name,
                                    object.phone,
                                    object.email,
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "email"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    object.status,
                                    "",
                                    object.name,
                                    object.phone,
                                    object.email,
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "phone"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    object.status,
                                    "",
                                    object.name,
                                    object.phone,
                                    object.email,
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "catname"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    object.status,
                                    "",
                                    object.name,
                                    object.phone,
                                    object.email,
                                    object.catname
                                  );
                                }
                                setFilter(updatedfilter);
                              }}
                              className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
                            />
                          ) : (
                            <select
                              className="bg-white border-2 w-32 mt-2 py-1 rounded-md"
                              onChange={(e) => {
                                const updatedfilter = [...filters];
                                updatedfilter[index] = {
                                  [Object.keys(updatedfilter[index])[0]]:
                                    e.target.value,
                                };
                                let object = Object.assign(
                                  {},
                                  ...Object.values(updatedfilter)
                                );
                                setFilter(updatedfilter);
                                getVendorList(
                                  paginationObject,
                                  object.status,
                                  "",
                                  object.name,
                                  object.phone,
                                  object.email,
                                  object.catname
                                );
                              }}
                            >
                              <option value={""}>select</option>
                              <option value={1}>Active</option>
                              <option value={4}>Inactive</option>
                              <option value={0}>Pending</option>
                              <option value={2}>Rejected</option>
                              <option value={3}>Probation</option>
                            </select>
                          )}
                          {
                            <AiOutlineClose
                              onClick={() => {
                                const updatedfilter = [...filters];
                                let object = Object.assign(
                                  {},
                                  ...Object.values(updatedfilter)
                                );
                                if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "name"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    "",
                                    "",
                                    "",
                                    object.phone,
                                    object.email,
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "email"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    "",
                                    "",
                                    object.name,
                                    object.phone,
                                    "",
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "phone"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    "",
                                    "",
                                    object.name,
                                    "",
                                    object.email,
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "status"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    "",
                                    "",
                                    object.name,
                                    object.phone,
                                    object.email,
                                    object.catname
                                  );
                                } else if (
                                  Object.keys(updatedfilter[index])[0] ===
                                  "catname"
                                ) {
                                  getVendorList(
                                    paginationObject,
                                    "",
                                    "",
                                    object.name,
                                    object.phone,
                                    object.email,
                                    // object.catname
                                    ""
                                  );
                                }
                                const update = updatedfilter.filter(
                                  (filte) =>
                                    Object.keys(filte)[0] !==
                                    Object.keys(item)[0]
                                );
                                // const updatedfilter = [...filters];
                                // updatedfilter[index] = {
                                //   [Object.keys(updatedfilter[index])[0]]:
                                //     e.target.value,
                                // };
                                setFilter(update);
                                setAddFilter([]);
                              }}
                              size={15}
                              className="mt-2 cursor-pointer text-grey-60000"
                            />
                          }
                        </div>
                      </>
                    ))}
                    {addFilter.map((_, index) => (
                      <>
                        <FilterItem
                          index={index}
                          setFilter={setFilter}
                          filters={filters}
                          setAddFilter={setAddFilter}
                          onRemovePress={() => {
                            const newData = [...addFilter];
                            newData.pop();
                            setAddFilter(newData);
                          }}
                        />
                      </>
                    ))}
                    {
                      <p
                        onClick={() => setAddFilter([...addFilter, 1])}
                        className="mt-2 font-semibold cursor-pointer text-blue-500"
                      >
                        + Add more filter
                      </p>
                    }
                  </div>
                </div>
              )}
            </div>
            <div className="flex ">
              <div ref={refColumns}>
                <button
                  onClick={() => setShowColumns(!showColumns)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 mr-2 rounded shadow-sm"
                >
                  Columns
                </button>

                {showColumns && (
                  <div className="relative">
                    <div className=" absolute shadow-lg right-0 bottom-20 bg-white rounded-md z-10">
                      {columns.map((item, index) => (
                        <div key={index}>
                          <MenuCheckBox
                            title={item.Header}
                            isCheck={item.isCheck}
                            setCheck={(index) => {
                              const newData = [...columns];
                              newData[index].isCheck = !newData[index].isCheck;
                              newData[index].show = !newData[index].show;
                              setHiddenCol(
                                newData.filter((item) => !item.show)
                              );
                              setColumns(newData);
                            }}
                            index={index}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {checkAccess(5, "edit") && (
              <div ref={ref}>
                <button
                  onClick={() => setShow(!show)}
                  className="bg-white hover:bg-grey-200 py-2 px-4 rounded shadow-sm"
                >
                  Bulk Action
                </button>

                {show && (
                  <div className="relative">
                    <div className=" absolute shadow-lg right-0 bottom-20 bg-white w-48 rounded-md z-10">
                      <Menu
                        title="Approve Vendors"
                        onClick={() => {
                          setShow(!show);
                          if (ids.length === 0) {
                            toast.warning("Please atleast one vendor!");
                            return;
                          }
                          dispatch(
                            vendorDeactivateAction({
                              ids,
                              status: "approve",
                              onSuccess: () => {
                                // dispatch(
                                // vendorAction({
                                //   onSucess: () => {
                                getVendorList(paginationObject, "", "");
                                //   },
                                //   onFailed: () => {},
                                // })
                                // );
                              },
                              onFailed: () => { },
                            })
                          );
                        }}
                      />
                      <hr></hr>
                      <Menu
                        title="Disable Selling"
                        onClick={() => {
                          if (ids.length === 0) {
                            toast.warning("Please atleast one vendor!");
                            return;
                          }
                          dispatch(
                            vendorDeactivateAction({
                              ids,
                              status: "disable",
                              onSuccess: () => {
                                // dispatch(
                                //   vendorAction({
                                //     onSucess: () => {
                                getVendorList(paginationObject, "", "");
                                //   },
                                //   onFailed: () => {},
                                // })
                                // );
                              },
                              onFailed: () => { },
                            })
                          );
                          setShow(!show);
                        }}
                      />
                      <hr></hr>
                      <Menu
                        title="Reject Vendors"
                        onClick={() => {
                          if (ids.length === 0) {
                            toast.warning("Please atleast one vendor!");
                            return;
                          }
                          dispatch(
                            vendorDeactivateAction({
                              ids,
                              status: "reject",
                              onSuccess: () => {
                                // dispatch(
                                // vendorAction({
                                //   onSucess: () => {
                                getVendorList(paginationObject, "", "");
                                //     },
                                //     onFailed: () => {},
                                //   })
                                // );
                              },
                              onFailed: () => { },
                            })
                          );
                          setShow(!show);
                        }}
                      />
                      <hr></hr>
                      <Menu
                        title="Warning Vendors"
                        onClick={() => {
                          if (ids.length === 0) {
                            toast.warning("Please atleast one vendor!");
                            return;
                          }
                          dispatch(
                            vendorDeactivateAction({
                              ids,
                              status: "warning",
                              onSuccess: () => {
                                // dispatch(
                                //   vendorAction({
                                //     onSucess: () => {
                                getVendorList(paginationObject, "", "");
                                //     },
                                //     onFailed: () => {},
                                //   })
                                // );
                              },
                              onFailed: () => { },
                            })
                          );
                          setShow(!show);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {vendorsData.length > 0 ? (
          <Simple
            getVendorList={getVendorList}
            getPageData={getPageData}
            onselect={onselect}
            pagination={paginationObject}
            columns={columns}
            setIds={(ids) => setIds(ids)}
            Loading={Loading}
            hiddenCol={hiddenCol}
            vendors={vendorsData}
          />
        ) : (
          <div className="ml-5">
            <p className="">There is no data</p>
          </div>
        )}
      </div>
    </>
  );
};
export default Index;
